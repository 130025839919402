@charset "UTF-8";
@font-face {
  font-family: "GT Walsheim Pro";
  src: url("../fonts/GTWalsheimPro-Light.eot");
  src: local("GT Walsheim Pro Light"), local("GTWalsheimPro-Light"), url("../fonts/GTWalsheimPro-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/GTWalsheimPro-Light.woff2") format("woff2"), url("../fonts/GTWalsheimPro-Light.woff") format("woff"), url("../fonts/GTWalsheimPro-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "GT Walsheim Pro";
  src: url("../fonts/GTWalsheimPro-Regular.eot");
  src: local("GT Walsheim Pro Regular"), local("GTWalsheimPro-Regular"), url("../fonts/GTWalsheimPro-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/GTWalsheimPro-Regular.woff2") format("woff2"), url("../fonts/GTWalsheimPro-Regular.woff") format("woff"), url("../fonts/GTWalsheimPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "GT Walsheim Pro";
  src: url("../fonts/GTWalsheimPro-ThinOblique.eot");
  src: local("GT Walsheim Pro Thin Oblique"), local("GTWalsheimPro-ThinOblique"), url("../fonts/GTWalsheimPro-ThinOblique.eot?#iefix") format("embedded-opentype"), url("../fonts/GTWalsheimPro-ThinOblique.woff2") format("woff2"), url("../fonts/GTWalsheimPro-ThinOblique.woff") format("woff"), url("../fonts/GTWalsheimPro-ThinOblique.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "GT Walsheim Pro";
  src: url("../fonts/GTWalsheimPro-Thin.eot");
  src: local("GT Walsheim Pro Thin"), local("GTWalsheimPro-Thin"), url("../fonts/GTWalsheimPro-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/GTWalsheimPro-Thin.woff2") format("woff2"), url("../fonts/GTWalsheimPro-Thin.woff") format("woff"), url("../fonts/GTWalsheimPro-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "GT Walsheim Pro";
  src: url("../fonts/GTWalsheimPro-BoldOblique.eot");
  src: local("GT Walsheim Pro Bold Oblique"), local("GTWalsheimPro-BoldOblique"), url("../fonts/GTWalsheimPro-BoldOblique.eot?#iefix") format("embedded-opentype"), url("../fonts/GTWalsheimPro-BoldOblique.woff2") format("woff2"), url("../fonts/GTWalsheimPro-BoldOblique.woff") format("woff"), url("../fonts/GTWalsheimPro-BoldOblique.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "GT Walsheim Pro Ultra";
  src: url("../fonts/GTWalsheimPro-UltraBold.eot");
  src: local("GT Walsheim Pro Ultra Bold"), local("GTWalsheimPro-UltraBold"), url("../fonts/GTWalsheimPro-UltraBold.eot?#iefix") format("embedded-opentype"), url("../fonts/GTWalsheimPro-UltraBold.woff2") format("woff2"), url("../fonts/GTWalsheimPro-UltraBold.woff") format("woff"), url("../fonts/GTWalsheimPro-UltraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "GT Walsheim Pro Ultra";
  src: url("../fonts/GTWalsheimPro-UltraBoldOblique.eot");
  src: local("GT Walsheim Pro Ultra Bold Oblique"), local("GTWalsheimPro-UltraBoldOblique"), url("../fonts/GTWalsheimPro-UltraBoldOblique.eot?#iefix") format("embedded-opentype"), url("../fonts/GTWalsheimPro-UltraBoldOblique.woff2") format("woff2"), url("../fonts/GTWalsheimPro-UltraBoldOblique.woff") format("woff"), url("../fonts/GTWalsheimPro-UltraBoldOblique.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "GT Walsheim Pro";
  src: url("../fonts/GTWalsheimPro-MediumOblique.eot");
  src: local("GT Walsheim Pro Medium Oblique"), local("GTWalsheimPro-MediumOblique"), url("../fonts/GTWalsheimPro-MediumOblique.eot?#iefix") format("embedded-opentype"), url("../fonts/GTWalsheimPro-MediumOblique.woff2") format("woff2"), url("../fonts/GTWalsheimPro-MediumOblique.woff") format("woff"), url("../fonts/GTWalsheimPro-MediumOblique.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "GT Walsheim Pro";
  src: url("../fonts/GTWalsheimPro-Bold.eot");
  src: local("GT Walsheim Pro Bold"), local("GTWalsheimPro-Bold"), url("../fonts/GTWalsheimPro-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/GTWalsheimPro-Bold.woff2") format("woff2"), url("../fonts/GTWalsheimPro-Bold.woff") format("woff"), url("../fonts/GTWalsheimPro-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "GT Walsheim Pro";
  src: url("../fonts/GTWalsheimPro-Medium.eot");
  src: local("GT Walsheim Pro Medium"), local("GTWalsheimPro-Medium"), url("../fonts/GTWalsheimPro-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/GTWalsheimPro-Medium.woff2") format("woff2"), url("../fonts/GTWalsheimPro-Medium.woff") format("woff"), url("../fonts/GTWalsheimPro-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "GT Walsheim Pro";
  src: url("../fonts/GTWalsheimPro-RegularOblique.eot");
  src: local("GT Walsheim Pro Regular Oblique"), local("GTWalsheimPro-RegularOblique"), url("../fonts/GTWalsheimPro-RegularOblique.eot?#iefix") format("embedded-opentype"), url("../fonts/GTWalsheimPro-RegularOblique.woff2") format("woff2"), url("../fonts/GTWalsheimPro-RegularOblique.woff") format("woff"), url("../fonts/GTWalsheimPro-RegularOblique.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "GT Walsheim Pro";
  src: url("../fonts/GTWalsheimPro-LightOblique.eot");
  src: local("GT Walsheim Pro Light Oblique"), local("GTWalsheimPro-LightOblique"), url("../fonts/GTWalsheimPro-LightOblique.eot?#iefix") format("embedded-opentype"), url("../fonts/GTWalsheimPro-LightOblique.woff2") format("woff2"), url("../fonts/GTWalsheimPro-LightOblique.woff") format("woff"), url("../fonts/GTWalsheimPro-LightOblique.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "GT Walsheim Pro Ultra";
  src: url("../fonts/GTWalsheimPro-UltraLight.eot");
  src: local("GT Walsheim Pro Ultra Light"), local("GTWalsheimPro-UltraLight"), url("../fonts/GTWalsheimPro-UltraLight.eot?#iefix") format("embedded-opentype"), url("../fonts/GTWalsheimPro-UltraLight.woff2") format("woff2"), url("../fonts/GTWalsheimPro-UltraLight.woff") format("woff"), url("../fonts/GTWalsheimPro-UltraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "GT Walsheim Pro Ultra";
  src: url("../fonts/GTWalsheimPro-UltraLightOblique.eot");
  src: local("GT Walsheim Pro Ultra Light Oblique"), local("GTWalsheimPro-UltraLightOblique"), url("../fonts/GTWalsheimPro-UltraLightOblique.eot?#iefix") format("embedded-opentype"), url("../fonts/GTWalsheimPro-UltraLightOblique.woff2") format("woff2"), url("../fonts/GTWalsheimPro-UltraLightOblique.woff") format("woff"), url("../fonts/GTWalsheimPro-UltraLightOblique.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "GT Walsheim Pro";
  src: url("../fonts/GTWalsheimPro-BlackOblique.eot");
  src: local("GT Walsheim Pro Black Oblique"), local("GTWalsheimPro-BlackOblique"), url("../fonts/GTWalsheimPro-BlackOblique.eot?#iefix") format("embedded-opentype"), url("../fonts/GTWalsheimPro-BlackOblique.woff2") format("woff2"), url("../fonts/GTWalsheimPro-BlackOblique.woff") format("woff"), url("../fonts/GTWalsheimPro-BlackOblique.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "GT Walsheim Pro";
  src: url("../fonts/GTWalsheimPro-Black.eot");
  src: local("GT Walsheim Pro Black"), local("GTWalsheimPro-Black"), url("../fonts/GTWalsheimPro-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/GTWalsheimPro-Black.woff2") format("woff2"), url("../fonts/GTWalsheimPro-Black.woff") format("woff"), url("../fonts/GTWalsheimPro-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: none;
}

input,
button,
textarea,
select {
  margin: 0;
  padding: 0;
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
  font-size: 99%;
  border-radius: 0;
}

textarea,
button,
input[type=email],
input[type=tel],
input[type=text],
input[type=number],
input[type=submit],
input[type=search] {
  -webkit-appearance: none;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button {
  display: none;
}

textarea[disabled],
button[disabled],
input[type=email][disabled],
input[type=tel][disabled],
input[type=text][disabled],
input[type=number][disabled],
input[type=submit][disabled],
input[type=search][disabled] {
  cursor: not-allowed;
}

select[disabled],
button[disabled],
input[disabled],
input[type=checkbox][disabled] + label,
input[type=radio][disabled] + label {
  cursor: not-allowed;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

table {
  font-size: inherit;
  border-collapse: collapse;
  border-spacing: 0;
}

table td, table th {
  padding: 4px 15px;
  vertical-align: top;
}

tr > :first-child {
  padding-left: 0;
}

th {
  font-weight: 500;
}

caption,
strong,
b {
  font-weight: 700;
}

ul ul {
  margin-bottom: 0;
}

form,
figure,
blockquote {
  margin: 0;
  padding: 0;
}

img,
video,
iframe {
  max-width: 100%;
  height: auto;
  border: none;
  vertical-align: middle;
}

header,
footer,
nav,
section,
main,
aside,
article,
figure,
figcaption {
  display: block;
}

html {
  font-size: 16px;
}

body {
  line-height: 1.625;
  font-family: "GT Walsheim Pro", sans-serif;
  font-size: 16px;
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
}

a {
  color: inherit;
  text-decoration: none;
}

caption,
th,
td {
  text-align: left;
  text-align: start;
}

abbr,
acronym {
  font-variant: normal;
  border-bottom: 1px dotted;
  cursor: help;
}

blockquote,
q {
  quotes: none;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

del {
  text-decoration: line-through;
}

ins {
  text-decoration: none;
}

pre,
code {
  font-family: monospace;
}

/* Vertical align */
.float_left {
  float: left;
}

.float_right {
  float: right;
}

/* Clear floats */
.clear,
#clear {
  height: 0.1px;
  font-size: 0.1px;
  line-height: 0.1px;
  clear: both;
}

/* Hide invisible elements */
.invisible,
.tns-visually-hidden {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.handorgel .accordion {
  display: none;
  overflow: hidden;
  height: 0;
  transition: height 0.1s ease 0.1s;
}
.handorgel .accordion.open {
  display: block;
  transition: height 0.2s ease;
}
.handorgel .accordion.active {
  overflow: visible;
}

.nav-list ul, .content-gallery ul, .module-newsreader__share ul, .module-jobs ul, .nav-list li, .content-gallery li, .module-newsreader__share li, .module-jobs li,
.mod_navigation ul,
.mod_navigation li,
.mod_changelanguage ul,
.mod_changelanguage li,
.mod_breadcrumb ul,
.mod_breadcrumb li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.list-items ul, .content-text__text ul, .content-features__text ul, .content-downloads ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.list-items li, .content-text__text li, .content-features__text li, .content-downloads li {
  display: block;
  margin: 20px 0;
  padding-left: 30px;
  background: url("../images/checkmark--primary.svg") 0 4px/19px 15px no-repeat;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}
.row > * {
  flex-grow: 1;
  padding-left: 10px;
  padding-right: 10px;
}
.row--center {
  justify-content: center;
  text-align: center;
}
.row--middle {
  align-items: center;
}
.row--bottom {
  align-items: flex-end;
}
@media screen and (min-width: 768px) {
  .row max-width--reverse-mobile {
    flex-direction: column-reverse;
  }
}

.col {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}
.col--center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}
.col--right {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.col-1 {
  padding-left: 10px;
  padding-right: 10px;
  width: 8.3333333333%;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.col-2 {
  padding-left: 10px;
  padding-right: 10px;
  width: 16.6666666667%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.col-3 {
  padding-left: 10px;
  padding-right: 10px;
  width: 25%;
}

.offset-3 {
  margin-left: 25%;
}

.col-4 {
  padding-left: 10px;
  padding-right: 10px;
  width: 33.3333333333%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.col-5 {
  padding-left: 10px;
  padding-right: 10px;
  width: 41.6666666667%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.col-6 {
  padding-left: 10px;
  padding-right: 10px;
  width: 50%;
}

.offset-6 {
  margin-left: 50%;
}

.col-7 {
  padding-left: 10px;
  padding-right: 10px;
  width: 58.3333333333%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.col-8 {
  padding-left: 10px;
  padding-right: 10px;
  width: 66.6666666667%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.col-9 {
  padding-left: 10px;
  padding-right: 10px;
  width: 75%;
}

.offset-9 {
  margin-left: 75%;
}

.col-10 {
  padding-left: 10px;
  padding-right: 10px;
  width: 83.3333333333%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.col-11 {
  padding-left: 10px;
  padding-right: 10px;
  width: 91.6666666667%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

.col-12 {
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}

.offset-12 {
  margin-left: 100%;
}

@media screen and (min-width: 768px) {
  .col-md-1 {
    padding-left: 10px;
    padding-right: 10px;
    width: 8.3333333333%;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .col-md-2 {
    padding-left: 10px;
    padding-right: 10px;
    width: 16.6666666667%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .col-md-3 {
    padding-left: 10px;
    padding-right: 10px;
    width: 25%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .col-md-4 {
    padding-left: 10px;
    padding-right: 10px;
    width: 33.3333333333%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .col-md-5 {
    padding-left: 10px;
    padding-right: 10px;
    width: 41.6666666667%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .col-md-6 {
    padding-left: 10px;
    padding-right: 10px;
    width: 50%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .col-md-7 {
    padding-left: 10px;
    padding-right: 10px;
    width: 58.3333333333%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .col-md-8 {
    padding-left: 10px;
    padding-right: 10px;
    width: 66.6666666667%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .col-md-9 {
    padding-left: 10px;
    padding-right: 10px;
    width: 75%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .col-md-10 {
    padding-left: 10px;
    padding-right: 10px;
    width: 83.3333333333%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .col-md-11 {
    padding-left: 10px;
    padding-right: 10px;
    width: 91.6666666667%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
  .col-md-12 {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }
  .offset-md-12 {
    margin-left: 100%;
  }
}
@media screen and (min-width: 1220px) {
  .col-lg-1 {
    padding-left: 10px;
    padding-right: 10px;
    width: 8.3333333333%;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .col-lg-2 {
    padding-left: 10px;
    padding-right: 10px;
    width: 16.6666666667%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .col-lg-3 {
    padding-left: 10px;
    padding-right: 10px;
    width: 25%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .col-lg-4 {
    padding-left: 10px;
    padding-right: 10px;
    width: 33.3333333333%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .col-lg-5 {
    padding-left: 10px;
    padding-right: 10px;
    width: 41.6666666667%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .col-lg-6 {
    padding-left: 10px;
    padding-right: 10px;
    width: 50%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .col-lg-7 {
    padding-left: 10px;
    padding-right: 10px;
    width: 58.3333333333%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .col-lg-8 {
    padding-left: 10px;
    padding-right: 10px;
    width: 66.6666666667%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .col-lg-9 {
    padding-left: 10px;
    padding-right: 10px;
    width: 75%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .col-lg-10 {
    padding-left: 10px;
    padding-right: 10px;
    width: 83.3333333333%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .col-lg-11 {
    padding-left: 10px;
    padding-right: 10px;
    width: 91.6666666667%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
  .col-lg-12 {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }
  .offset-lg-12 {
    margin-left: 100%;
  }
}
.formbody h1 {
  margin-bottom: 20px;
}
.formbody fieldset {
  margin: 0;
  padding: 0;
  border: none;
}
.formbody .widget {
  position: relative;
  padding: 10px;
  font-size: 16px;
  line-height: 24px;
}
.formbody .widget p.error {
  position: absolute;
  top: -5px;
  right: 15px;
  font-size: 12px;
  color: #F00;
}
.formbody .widget-text input,
.formbody .widget-text select,
.formbody .widget-text textarea,
.formbody .widget-text span.input,
.formbody .widget-select input,
.formbody .widget-select select,
.formbody .widget-select textarea,
.formbody .widget-select span.input,
.formbody .widget-textarea input,
.formbody .widget-textarea select,
.formbody .widget-textarea textarea,
.formbody .widget-textarea span.input {
  display: block;
  width: 100%;
  height: 50px;
  padding: 13px 10px;
  font-size: inherit;
  line-height: inherit;
  background: #fff;
  border: none;
  border-radius: 4px;
  outline: none;
}
.formbody .widget-text select,
.formbody .widget-select select,
.formbody .widget-textarea select {
  position: absolute;
  top: 10px;
  opacity: 0;
}
.formbody .widget-text textarea,
.formbody .widget-select textarea,
.formbody .widget-textarea textarea {
  height: 130px;
  resize: vertical;
}
.formbody .widget-text label,
.formbody .widget-select label,
.formbody .widget-textarea label {
  position: absolute;
  left: 20px;
  right: 20px;
  top: 23px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  line-height: 24px;
  color: rgba(141, 141, 141, 0.5);
  visibility: hidden;
  pointer-events: none;
}
.formbody .widget-text.empty label,
.formbody .widget-select.empty label,
.formbody .widget-textarea.empty label {
  visibility: visible;
}
.formbody .widget-text.focus label,
.formbody .widget-select.focus label,
.formbody .widget-textarea.focus label {
  color: inherit;
}
.formbody .widget-text.focus input,
.formbody .widget-text.focus select,
.formbody .widget-text.focus span.input,
.formbody .widget-select.focus input,
.formbody .widget-select.focus select,
.formbody .widget-select.focus span.input,
.formbody .widget-textarea.focus input,
.formbody .widget-textarea.focus select,
.formbody .widget-textarea.focus span.input {
  box-shadow: 0 14px 10px -4px rgba(195, 216, 235, 0.8);
}
.formbody .widget-text.valid:after {
  content: "";
  position: absolute;
  top: 25px;
  right: 20px;
  display: block;
  width: 24px;
  height: 24px;
  background: url("../images/checkmark--primary.svg") center center/19px 15px no-repeat;
  pointer-events: none;
}
.formbody .widget-select:after {
  content: "";
  position: absolute;
  top: 25px;
  right: 20px;
  display: block;
  width: 24px;
  height: 24px;
  background: url("../images/select.svg") center center/16px 10px no-repeat;
  opacity: 0.5;
  pointer-events: none;
}
.formbody .widget-select.focus::after {
  background-image: url("../images/select--active.svg");
  opacity: 1;
}
.formbody .widget-select.valid::after {
  background-image: url("../images/select--filled.svg");
  opacity: 1;
}
.formbody .widget-radio > fieldset,
.formbody .widget-checkbox > fieldset {
  display: flex;
  flex-wrap: wrap;
  column-gap: 25px;
}
.formbody .widget-radio > fieldset > legend,
.formbody .widget-checkbox > fieldset > legend {
  float: left;
  width: 30%;
  padding: 13px 0;
}
.formbody .widget-radio > fieldset > span,
.formbody .widget-checkbox > fieldset > span {
  display: inline-block;
  padding: 13px 0;
}
.formbody .widget-radio.vertical > fieldset,
.formbody .widget-checkbox.vertical > fieldset {
  flex-direction: column;
}
.formbody .widget-radio.vertical > fieldset > legend,
.formbody .widget-checkbox.vertical > fieldset > legend {
  float: none;
  width: auto;
}
.formbody .widget-radio.vertical > fieldset > legend,
.formbody .widget-radio.vertical > fieldset > span,
.formbody .widget-checkbox.vertical > fieldset > legend,
.formbody .widget-checkbox.vertical > fieldset > span {
  padding: 5px 0;
}
.formbody .widget-radio input,
.formbody .widget-checkbox input {
  position: absolute;
  opacity: 0;
}
.formbody .widget-radio input + label,
.formbody .widget-checkbox input + label {
  position: relative;
  padding-left: 28px;
}
.formbody .widget-radio input + label::before,
.formbody .widget-checkbox input + label::before {
  content: "";
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  background: #fff;
  pointer-events: none;
}
.formbody .widget-radio input:focus + label:before,
.formbody .widget-checkbox input:focus + label:before {
  box-shadow: 0 14px 10px -4px rgba(195, 216, 235, 0.8);
}
.formbody .widget-radio input:checked + label::after,
.formbody .widget-checkbox input:checked + label::after {
  content: "";
  position: absolute;
  z-index: 10;
  top: 5px;
  left: 5px;
  width: 8px;
  height: 8px;
  background: #F8A700;
  pointer-events: none;
}
.formbody .widget-radio input[type=radio] + label::before, .formbody .widget-radio input[type=radio] + label::after,
.formbody .widget-checkbox input[type=radio] + label::before,
.formbody .widget-checkbox input[type=radio] + label::after {
  border-radius: 50%;
}
.formbody .widget-submit {
  clear: both;
  text-align: right;
}
.formbody .widget-submit button {
  margin-top: 0 !important;
  pointer-events: auto !important;
}

@media screen and (min-width: 768px) {
  .formbody fieldset:not([data-cff-condition], .radio_container, .checkbox_container) {
    float: left;
    width: 50%;
  }
  .formbody fieldset:not([data-cff-condition], .radio_container, .checkbox_container) + .widget {
    clear: left;
  }
}
.h1, .ce_rsce_partner__headline, .content-cta__text, .module-event_teaser__headline, main h1 {
  margin: 0 0 30px;
  font-size: 30px;
  line-height: 38px;
  font-weight: 700;
}
.h1.content-headline, .content-headline.ce_rsce_partner__headline, .content-headline.content-cta__text, .content-headline.module-event_teaser__headline, main h1.content-headline {
  margin-bottom: 40px;
}
@media screen and (min-width: 768px) {
  .h1, .ce_rsce_partner__headline, .content-cta__text, .module-event_teaser__headline, main h1 {
    margin-bottom: 50px;
    font-size: 48px;
    line-height: 56px;
  }
}

.h2, main h2 {
  margin: 40px 0 10px;
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
}

.h3, main h3 {
  font-size: 18px;
  line-height: 26px;
  margin: 40px 0 10px;
  font-weight: 700;
}

.dateline, .content-text__dateline {
  margin: 0 0 10px;
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
}

p a {
  text-decoration: underline;
}
p a:hover {
  color: #F8A700;
}

.download-app {
  display: block;
  text-indent: -999em;
}
.download-app--apple {
  background: url("../images/apple-store.svg") 0 0/contain no-repeat;
}
.download-app--google {
  background: url("../images/google-store.svg") 0 0/contain no-repeat;
}

@media screen and (max-width: 767px) {
  body.has-overlay {
    overflow: hidden;
  }
}
.section-header {
  background: #fff;
}
.section-header__inside {
  position: relative;
  height: 80px;
  padding: 30px 20px 0;
}
.section-header__logo {
  display: block;
  width: 150px;
  height: 30px;
  background: url("../../images/logo.svg") 0 0/contain no-repeat;
}
.section-header .mod_changelanguage {
  position: relative;
  background: #fff;
}
.section-header .mod_changelanguage button {
  padding: 5px 10px;
  background: none;
  border: none;
  cursor: pointer;
}
.section-header .mod_changelanguage a {
  display: block;
}
.section-header .mod_changelanguage ul {
  position: absolute;
  right: 0;
  padding: 15px 20px;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 0 10px 3px rgba(221, 239, 255, 0.8);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.1s ease-in-out;
  z-index: 10;
}
.section-header .mod_changelanguage li {
  padding: 5px;
  font-size: 14px;
  line-height: 22px;
}
.section-header .mod_changelanguage li a.active {
  font-weight: 700;
}
.section-header .mod_changelanguage:focus ul, .section-header .mod_changelanguage:hover ul, .section-header .mod_changelanguage.hover ul {
  opacity: 1;
  pointer-events: auto;
}
.section-header .cta__links {
  margin: 0;
}
.section-header .section-header__demo {
  display: block;
  padding: 11px 20px 10px 50px;
  background: #000 url("../../images/arrow--white.svg") 20px 50%/22px auto no-repeat;
  border: none;
  border-radius: 100px;
}

@media screen and (max-width: 767px) {
  .section-header__toggle {
    position: absolute;
    right: 20px;
    top: 30px;
    width: 24px;
    height: 24px;
    background: none;
    border: none;
    cursor: pointer;
  }
  .section-header__toggle span {
    display: block;
    width: 16px;
    height: 4px;
    background: #000;
    transition: all 0.1s ease-in-out;
  }
  .section-header__toggle span:before, .section-header__toggle span:after {
    content: "";
    position: absolute;
    left: 0;
    width: 24px;
    height: 4px;
    background: #000;
    transition: all 0.1s ease-in-out;
  }
  .section-header__toggle span:before {
    top: 0;
  }
  .section-header__toggle span:after {
    bottom: 0;
  }
  body.has-overlay .section-header__toggle span {
    background: transparent;
  }
  body.has-overlay .section-header__toggle span:before {
    top: 10px;
    transform: rotateZ(45deg);
  }
  body.has-overlay .section-header__toggle span:after {
    bottom: 10px;
    transform: rotateZ(-45deg);
  }
  .section-header__overlay {
    opacity: 0;
    pointer-events: none;
    display: block;
    position: fixed;
    overflow-y: auto;
    top: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    transition: opacity 0.2s ease-in-out;
    z-index: 999;
  }
  body.has-overlay .section-header__overlay {
    opacity: 1;
    pointer-events: auto;
  }
  .section-header .mod_navigation {
    margin-top: 72px;
    padding: 0 30px;
    font-size: 18px;
    line-height: 26px;
  }
  .section-header .mod_navigation a {
    display: block;
  }
  .section-header .mod_navigation .level_1 {
    border-top: 1px solid #DDEFFF;
  }
  .section-header .mod_navigation .level_1 > li {
    position: relative;
    border-bottom: 1px solid #DDEFFF;
  }
  .section-header .mod_navigation .level_1 > li > a {
    padding: 15px 10px 16px;
  }
  .section-header .mod_navigation .level_1 > li > a > .emoji {
    margin-right: 5px;
  }
  .section-header .mod_navigation .level_1 > li::after {
    content: "";
    position: absolute;
    top: 24px;
    right: 20px;
    display: block;
    width: 12px;
    height: 12px;
    background: url("../../images/nav--forward.svg") center center/contain no-repeat;
    transition: transform 0.1s ease-in-out;
    pointer-events: none;
  }
  .section-header .mod_navigation .level_1 > li.submenu::after {
    background: url("../../images/nav--open.svg") center center/8px 5px no-repeat;
  }
  .section-header .mod_navigation .level_1 > li > div {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    font-size: 14px;
    line-height: 22px;
  }
  .section-header .mod_navigation .level_1 > li > div a {
    padding: 0 10px 10px;
  }
  .section-header .mod_navigation .level_1 > li > div li:last-child {
    padding-bottom: 20px;
  }
  .section-header .mod_navigation .level_1 > li.hover.submenu::after {
    transform: rotateZ(180deg);
  }
  .section-header .mod_navigation .level_1 > li.hover > div {
    max-height: 100vh;
  }
  .section-header .mod_changelanguage {
    position: absolute;
    top: 5px;
    right: 40px;
  }
  .section-header .mod_changelanguage > a {
    font-weight: 700;
  }
  .section-header .mod_changelanguage > a::after {
    content: "";
    position: absolute;
    right: 10px;
    top: 12px;
    display: block;
    width: 12px;
    height: 12px;
    background: url("../../images/nav--open.svg") center center/8px 5px no-repeat;
  }
  .section-header__demo {
    position: absolute;
    top: 0;
    left: 30px;
  }
}
@media screen and (min-width: 768px) {
  .section-header__inside {
    height: auto;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    padding: 22px 30px;
  }
  .section-header__logo {
    width: 180px;
    height: 40px;
    flex-shrink: 0;
    align-self: center;
  }
  .section-header__toggle {
    display: none;
  }
  .section-header__overlay {
    opacity: 1;
    pointer-events: all;
    position: static;
    overflow-y: visible;
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    align-items: center;
  }
  .section-header .mod_navigation {
    padding: 40px 0 10px;
    flex-grow: 1;
    max-width: 800px;
  }
  .section-header .mod_navigation .hide-in-header,
  .section-header .mod_navigation .emoji {
    display: none;
  }
  .section-header .mod_navigation .level_1 {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    line-height: 1;
  }
  .section-header .mod_navigation .level_1 > li {
    position: relative;
    padding: 0;
  }
  .section-header .mod_navigation .level_1 > li > a,
  .section-header .mod_navigation .level_1 > li > div > div > a {
    font-weight: 700;
  }
  .section-header .mod_navigation .level_1 > li > a {
    padding-bottom: 4px;
  }
  .section-header .mod_navigation .level_1 > li > div {
    position: absolute;
    z-index: 100;
    top: 25px;
    left: -5px;
    padding-top: 5px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-in-out;
  }
  .section-header .mod_navigation .level_1 > li > div > div {
    padding: 20px 25px;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 0 10px 3px rgba(221, 239, 255, 0.8);
  }
  .section-header .mod_navigation .level_1 > li:hover > a, .section-header .mod_navigation .level_1 > li.hover > a, .section-header .mod_navigation .level_1 > li.active > a, .section-header .mod_navigation .level_1 > li.trail > a {
    border-bottom: 5px solid #F8A700;
  }
  .section-header .mod_navigation .level_1 > li:hover div, .section-header .mod_navigation .level_1 > li.hover div {
    opacity: 1;
    pointer-events: auto;
  }
  .section-header .mod_navigation .level_2 > li {
    font-size: 14px;
    line-height: 22px;
    margin-top: 10px;
  }
  .section-header .mod_navigation a {
    white-space: nowrap;
  }
  .section-header .mod_changelanguage {
    position: absolute;
    top: 30px;
    right: 320px;
  }
  .section-header .mod_changelanguage ul {
    left: -15px;
    right: auto;
  }
  .section-header .mod_changelanguage li {
    min-width: 120px;
  }
  .section-header__demo {
    position: absolute;
    top: 20px;
    right: 20px;
    white-space: nowrap;
  }
}
@media screen and (min-width: 1220px) {
  body {
    padding-top: 100px;
  }
  :target {
    scroll-margin-top: 100px;
  }
  .section-header {
    position: fixed;
    inset: 0 0 auto 0;
    background: #fff;
    z-index: 99;
  }
  .section-header__inside {
    display: flex;
    height: 100px;
  }
  .section-header__overlay {
    flex-wrap: nowrap;
    margin-left: 30px;
  }
  .section-header .mod_navigation {
    max-width: none;
    padding: 0 20px;
  }
  .section-header .mod_navigation ul {
    max-width: 700px;
  }
  .section-header .mod_changelanguage, .section-header__demo {
    position: relative;
    inset: auto;
  }
  .section-header .mod_changelanguage {
    margin-right: 40px;
  }
}
@media screen and (min-width: 1260px) {
  .section-header__inside {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.section-footer {
  font-size: 14px;
  line-height: 22px;
}
.section-footer__navigation {
  background: #DDEFFF;
}
.section-footer__navigation-inside {
  padding: 40px 20px 20px;
}
.section-footer__navigation .level_1 {
  columns: 2;
}
.section-footer__navigation .level_1 > li {
  padding: 20px 0;
  break-inside: avoid;
  page-break-inside: avoid;
}
.section-footer__navigation .level_1 > li > a {
  font-weight: 700;
}
.section-footer__logo {
  display: block;
  width: 150px;
  height: 30px;
  margin-bottom: 10px;
  background: url("../../images/logo--black.svg") 0 0/contain no-repeat;
}
.section-footer__links {
  color: #fff;
  background: #000;
}
.section-footer__links-inside {
  padding: 20px;
}
.section-footer__newsletter .cta__links {
  margin-top: 0;
}
.section-footer__newsletter .cta__links > div > button {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0 0 0 30px;
  background: url("../../images/arrow--white.svg") 0 50%/24px auto no-repeat;
  border: none;
}
.section-footer__newsletter .cta__links > div > button:hover {
  background-color: transparent;
  border: none;
  text-decoration: underline;
}
.section-footer__newsletter .cta__links > div > button__links {
  margin: 0;
}
.section-footer__apps {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.section-footer__app {
  width: 120px;
  height: 36px;
  margin-top: 15px;
  margin-right: 10px;
}
.section-footer__social {
  display: block;
  width: 24px;
  height: 24px;
  margin-top: 15px;
  margin-left: 10px;
  text-indent: -999em;
}
.section-footer__social--linkedin {
  background: url("../../images/linkedin.svg") 0 0/contain no-repeat;
}
.section-footer__legal {
  margin-top: 15px;
  justify-content: center;
}
.section-footer__legal, .section-footer__legal ul {
  display: flex;
}
.section-footer__legal li:after {
  padding: 0 5px;
  content: "|";
}
.section-footer__copyright {
  white-space: nowrap;
}
.section-footer__top {
  height: 40px;
  background: #000;
}
.section-footer__top > a {
  position: fixed;
  z-index: 600;
  right: 10px;
  bottom: 71px;
  display: block;
  width: 32px;
  height: 32px;
  background: url("../../images/backtotop.svg") 0 0/contain no-repeat;
  text-indent: -999em;
  box-shadow: 0 0 10px 3px rgba(221, 239, 255, 0.8);
}
.section-footer__support {
  background: #000;
  height: 80px;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}
body.has-overlay .section-footer__support {
  opacity: 0;
  pointer-events: none;
}
.section-footer__support-toggle {
  position: absolute;
  right: 0;
  top: 0;
  width: 34px;
  height: 44px;
  background: url("../../images/plus--primary.svg") center center/14px 14px no-repeat;
  border: none;
  cursor: pointer;
}
.section-footer__support-content {
  position: fixed;
  z-index: 600;
  right: 10px;
  bottom: 15px;
  width: 100px;
  max-height: 44px;
  padding: 12px 34px 12px 10px;
  overflow: hidden;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px 3px rgba(221, 239, 255, 0.8);
  cursor: pointer;
  background: #fff;
}
.section-footer__support-content h3 {
  margin-top: 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
}
.section-footer__support-content p {
  margin: 0;
}
.section-footer__support-content--open {
  cursor: auto;
  bottom: 0;
  width: 100vw;
  inset: auto 0 0 0;
  padding: 20px 20px 50px;
  font-size: 18px;
  line-height: 26px;
  border-radius: 0;
  box-shadow: 0 -7px 10px -1px rgba(189, 192, 195, 0.8);
  max-height: 1000px;
  animation: 0.3s ease-in-out support-mobile forwards;
}
.section-footer__support-content--open h3 {
  padding: 0;
  font-size: 20px;
  line-height: 23px;
}
.section-footer__support-content--open .section-footer__support-toggle {
  right: 10px;
  top: 8px;
  background: url("../../images/minus.svg") center center/20px 20px no-repeat;
}
@keyframes support-mobile {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@media screen and (min-width: 768px) {
  .section-footer__navigation-inside {
    max-width: 1220px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
  }
  .section-footer__navigation .mod_navigation .level_1 {
    columns: 4;
    column-gap: 20px;
  }
  .section-footer__links-inside {
    overflow: hidden;
    max-width: 1220px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 20px 40px;
  }
  .section-footer__newsletter {
    float: right;
    width: 33.3333333333%;
    margin-top: 30px;
    padding-left: calc(8.3333333333% + 10px);
  }
  .section-footer__support {
    height: 100px;
  }
  .section-footer__support-toggle {
    width: 46px;
    height: 46px;
    background-size: 20px 20px;
  }
  .section-footer__support-content {
    left: auto;
    width: 350px;
    max-height: 46px;
    padding: 13px 15px;
    box-shadow: 0 0 10px 3px #F2F2F2;
    transition: all 0.3s ease-in-out;
  }
  .section-footer__support-content h3 {
    font-size: 20px;
    line-height: 22px;
  }
  .section-footer__support-content--open {
    animation: none;
    bottom: 15px;
    right: 15px;
    max-height: 300px;
    padding: 20px 20px 50px;
    border-radius: 4px;
  }
}
@media screen and (min-width: 968px) {
  .section-footer__links-inside {
    padding-left: 10px;
    padding-right: 10px;
  }
  .section-footer__apps {
    float: left;
    width: 33.3333333333%;
    padding: 0 10px;
  }
  .section-footer__app {
    margin-top: 20px;
  }
  .section-footer__social {
    margin-top: 20px;
  }
  .section-footer__legal {
    width: 33.3333333333%;
    margin-top: 30px;
  }
}
@media screen and (min-width: 1220px) {
  .section-footer__social {
    margin-left: calc(75% - 250px);
  }
}
@media screen and (min-width: 1680px) {
  .section-footer__top, .section-footer__support {
    height: 0;
  }
}
main {
  padding: 0 20px 60px 20px;
  font-size: 18px;
  line-height: 26px;
}
main p {
  margin: 1em 0;
}
main figcaption {
  margin-top: 20px;
  font-size: 14px;
  line-height: 22px;
}

@media screen and (min-width: 768px) {
  main > .inside {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media screen and (min-width: 1220px) {
  main {
    overflow-x: hidden;
  }
}
.page--404 main {
  text-align: center;
}
.page--404 main .content-text__inside {
  justify-content: center;
}
.page--404 main .content-text--headline-h2 {
  padding-bottom: 0;
}
.page--404 main .content-image {
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
}
.page--404 main .content-hyperlink {
  margin-left: 15px;
  margin-right: 15px;
}

.module-events {
  margin-top: -50px;
}
.module-events__month {
  margin-bottom: 30px;
  padding: 20px 20px 0;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px 3px rgba(221, 239, 255, 0.8);
}
.module-events__number {
  float: right;
  position: relative;
  top: -0.25em;
  font-size: 60px;
  line-height: 69px;
  color: #F8A700;
  font-weight: 700;
}
.module-events__title {
  margin: 0 0 30px;
}
.module-events__event {
  padding: 20px 0;
}
.module-events__event + .module-events__event {
  border-top: 2px solid #DDEFFF;
}
.module-events__image {
  text-align: center;
}
.module-events__headline {
  margin-top: 20px;
}
.module-events__link {
  display: block;
  margin-top: 10px;
  width: 24px;
  height: 24px;
  background: url("../../images/website.svg") 0 0/contain no-repeat;
  text-indent: -999em;
}

@media screen and (min-width: 768px) {
  .module-events {
    margin-top: -80px;
  }
  .module-events__month {
    padding: 40px 35px 5px;
  }
  .module-events__number {
    top: -5px;
    font-size: 80px;
    line-height: 88px;
  }
  .module-events__title {
    margin: 0 0 0 30px;
  }
  .module-events__event {
    display: flex;
    padding: 25px 25px 35px;
  }
  .module-events__image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 210px;
  }
  .module-events__headline {
    margin-top: 0;
  }
  .module-events__content {
    margin-left: 30px;
  }
}
@media screen and (min-width: 1220px) {
  .module-events {
    width: 83.3333333333%;
  }
}
.module-event_teaser {
  position: fixed;
  z-index: 900;
  bottom: -100%;
  left: 0;
  right: 0;
  background: #F8A700;
  animation: 0.3s ease-in-out 1s event-teaser forwards;
}
.module-event_teaser--hidden {
  bottom: 0;
  animation: ease-in-out 3s event-teaser reverse forwards;
}
.module-event_teaser__inside {
  max-width: 1220px;
  margin: 0 auto;
  padding: 50px 20px;
}
.module-event_teaser__close {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  width: 64px;
  height: 64px;
  background: url("../../images/close.svg") center center/24px 24px no-repeat;
  border: none;
  cursor: pointer;
}
.module-event_teaser__headline {
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .module-event_teaser__inside {
    padding: 70px 20px;
  }
}
@keyframes event-teaser {
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 0;
  }
}
.mod_faqlist {
  padding: 60px 0;
}

@media screen and (min-width: 768px) {
  .mod_faqlist {
    padding-bottom: 100px;
  }
}
.module-faqlist-categories {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.module-faqlist-categories__category {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 30px;
}

@media screen and (min-width: 600px) {
  .module-faqlist-categories {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 50px -10px;
  }
  .module-faqlist-categories__category {
    padding-left: 10px;
    padding-right: 10px;
    width: 50%;
  }
}
@media screen and (min-width: 768px) {
  .module-faqlist-categories__category {
    padding-left: 10px;
    padding-right: 10px;
    width: 33.3333333333%;
  }
}
.mod_search .formbody {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}
.mod_search .formbody > * {
  flex-grow: 1;
  padding-left: 10px;
  padding-right: 10px;
}
.mod_search .widget-text {
  flex-grow: 1;
}
.mod_search .widget-text input {
  height: 56px;
  background: #DDEFFF;
}

.module-jobs__job {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 20px;
  font-weight: 700;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px 3px rgba(221, 239, 255, 0.8);
}
.module-jobs__more {
  flex-shrink: 0;
  display: block;
  width: 24px;
  height: 24px;
  margin-left: 20px;
  text-indent: -999em;
  background: url("../../images/plus--primary.svg") 0 0/contain no-repeat;
}

@media screen and (min-width: 768px) {
  .module-jobs__list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
  }
  .module-jobs__list > * {
    flex-grow: 1;
    padding-left: 10px;
    padding-right: 10px;
  }
  .module-jobs__item {
    padding-left: 10px;
    padding-right: 10px;
    width: 91.6666666667%;
  }
  .module-jobs__more {
    width: auto;
    height: auto;
    color: #F8A700;
    text-decoration: underline;
    text-indent: 0;
    background: none;
  }
}
.module-newsfeatured__headline,
.module-newslist__headline,
.module-newsintro__headline {
  margin-top: 6px;
}
.module-newsfeatured__date,
.module-newslist__date,
.module-newsintro__date {
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
}
.module-newsfeatured img,
.module-newslist img,
.module-newsintro img {
  width: 100%;
}

.module-newsintro {
  margin-top: 60px;
}
.module-newsintro__articles {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin-bottom: 60px;
}
.module-newsintro__articles > * {
  flex-grow: 1;
  padding-left: 10px;
  padding-right: 10px;
}
.module-newsintro:last-child .module-newsintro__articles {
  margin-bottom: 0;
}
.module-newsintro__item {
  position: relative;
  width: 300px;
  flex-shrink: 0;
  padding-left: 10px;
  padding-right: 10px;
}
.module-newsintro__categories {
  position: absolute;
  z-index: 10;
  top: 20px;
  left: 20px;
  font-size: 14px;
  line-height: 12px;
}
.module-newsintro__category {
  padding: 6px 12px 7px;
  background: #fff;
  border-radius: 100px;
}
.module-newsintro h2 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
}

@media screen and (min-width: 768px) {
  .module-newsintro {
    margin-top: 100px;
  }
  .module-newsintro__articles {
    overflow: visible;
    margin-bottom: 100px;
  }
  .module-newsintro:last-child .module-newsintro__articles {
    margin-bottom: 40px;
  }
  .module-newsintro__item {
    padding-left: 10px;
    padding-right: 10px;
    width: 25%;
  }
}
@media screen and (min-width: 1220px) {
  .module-newsintro {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
  }
  .module-newsintro > * {
    flex-grow: 1;
    padding-left: 10px;
    padding-right: 10px;
  }
  .module-newsintro .dateline, .module-newsintro .content-text__dateline,
  .module-newsintro h1 {
    padding-left: 10px;
    padding-right: 10px;
    width: 83.3333333333%;
  }
}
@media screen and (max-width: 767px) {
  .module-newsfeatured {
    position: relative;
    margin: 0 -20px;
    padding-bottom: 60px;
    background: #DDEFFF;
  }
  .module-newsfeatured__animation {
    display: none;
  }
  .module-newsfeatured__item-inside {
    position: relative;
    padding: 0 20px;
  }
  .module-newsfeatured__image {
    margin-left: -20px;
    margin-right: -20px;
  }
  .module-newsfeatured__categories {
    position: absolute;
    z-index: 10;
    top: 20px;
    left: 20px;
    font-size: 16px;
    line-height: 24px;
  }
  .module-newsfeatured__category {
    padding: 11px 24px;
    background: #fff;
    border-radius: 100px;
  }
  .module-newsfeatured__date {
    margin-top: 30px;
  }
  .module-newsfeatured__more {
    color: #F8A700;
    text-decoration: underline;
    text-transform: lowercase;
  }
  .module-newsfeatured .tns-nav {
    position: absolute;
    z-index: 100;
    top: 54vw;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
  }
  .module-newsfeatured .tns-nav button {
    margin: 0 3px;
    width: 9px;
    height: 9px;
    background: #F8A700;
    border: none;
    border-radius: 50%;
    opacity: 0.3;
  }
  .module-newsfeatured .tns-nav button.tns-nav-active {
    opacity: 1;
  }
  .module-newsfeatured__cta,
  .module-newsfeatured .tns-controls {
    display: none !important;
  }
}
@media screen and (min-width: 768px) {
  .module-newsfeatured {
    position: relative;
    margin-bottom: 100px;
    padding: 100px 0;
    position: relative;
  }
  .module-newsfeatured:before {
    content: "";
    position: absolute;
    left: -20px;
    right: -20px;
    top: 0;
    bottom: 0;
    background: #DDEFFF;
    z-index: -1;
  }
}
@media screen and (min-width: 768px) and (min-width: 768px) {
  .module-newsfeatured:before {
    left: -30px;
    right: -30px;
  }
}
@media screen and (min-width: 768px) and (min-width: 1220px) {
  .module-newsfeatured:before {
    left: calc(-50vw + 590px);
    right: calc(-50vw + 590px);
  }
}
@media screen and (min-width: 768px) {
  .module-newsfeatured__inside {
    margin: -30px;
  }
  .module-newsfeatured__animation {
    position: absolute;
    inset: 0 0 auto 0;
  }
  .module-newsfeatured__item-inside {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    padding: 30px;
    flex-direction: row-reverse;
  }
  .module-newsfeatured__item-inside > * {
    flex-grow: 1;
    padding-left: 10px;
    padding-right: 10px;
  }
  .module-newsfeatured__category {
    margin-bottom: 1em;
  }
  .module-newsfeatured__image {
    padding-left: 10px;
    padding-right: 10px;
    width: 50%;
    margin-left: 8.3333333333%;
  }
  .module-newsfeatured__image img {
    background: #fff;
    box-shadow: 0 10px 20px 0 #7D9AB1;
  }
  .module-newsfeatured__content {
    padding-left: 10px;
    padding-right: 10px;
    width: 41.6666666667%;
  }
  .module-newsfeatured .tns-nav, .module-newsfeatured__more {
    display: none;
  }
  .module-newsfeatured .tns-controls {
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: -100px;
    right: -100px;
    top: calc(50% - 30px);
  }
  .module-newsfeatured .tns-controls button {
    width: 60px;
    height: 60px;
    border: none;
    text-indent: -999em;
  }
  .module-newsfeatured .tns-controls button[data-controls=prev] {
    background: transparent url("../../images/slider--prev.svg") 0 50%/24px auto no-repeat;
  }
  .module-newsfeatured .tns-controls button[data-controls=next] {
    background: transparent url("../../images/slider--next.svg") 100% 50%/24px auto no-repeat;
  }
  .module-newsfeatured .tns-controls button:disabled {
    opacity: 0.15;
    cursor: not-allowed;
  }
}
@media screen and (max-width: 767px) {
  .module-newslist__item {
    margin: 30px 0;
    padding: 20px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 0 10px 3px rgba(221, 239, 255, 0.8);
    font-size: 12px;
    line-height: 20px;
  }
  .module-newslist__category {
    display: inline-block;
    margin-bottom: 17px;
    padding: 6px 12px 7px;
    background: #DDEFFF;
    border-radius: 100px;
    font-size: 10px;
    line-height: 1.2;
  }
  .module-newslist__headline {
    font-size: 14px;
    line-height: 22px;
  }
  .module-newslist__content {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
  }
  .module-newslist__content > * {
    flex-grow: 1;
    padding-left: 10px;
    padding-right: 10px;
  }
  .module-newslist__image {
    padding-left: 10px;
    padding-right: 10px;
    width: 50%;
  }
  .module-newslist__text {
    padding-left: 10px;
    padding-right: 10px;
    width: 50%;
  }
  .module-newslist__more {
    display: block;
    margin-top: 1em;
    font-weight: 700;
    text-decoration: underline;
  }
}
@media screen and (min-width: 768px) {
  .module-newslist {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
  }
  .module-newslist > * {
    flex-grow: 1;
    padding-left: 10px;
    padding-right: 10px;
  }
  .module-newslist__item {
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    width: 50%;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .module-newslist__inside {
    position: relative;
    padding: 66% 30px 40px;
    background: #DDEFFF;
  }
  .module-newslist__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  .module-newslist__categories {
    position: absolute;
    z-index: 10;
    top: 20px;
    left: 20px;
    font-size: 16px;
    line-height: 24px;
  }
  .module-newslist__category {
    padding: 11px 24px;
    background: #fff;
    border-radius: 100px;
  }
  .module-newslist__more {
    color: #F8A700;
    text-decoration: underline;
    text-transform: lowercase;
  }
  .module-newslist .content-cta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    margin: 0;
    padding: 30px;
    background: #F8A700;
    border: 10px solid #fff;
  }
  .module-newslist .content-cta:before {
    content: none;
  }
  .module-newslist .content-cta__text:before {
    content: "";
    position: relative;
    top: 5px;
    display: inline-block;
    width: 100px;
    height: 1em;
    background: url("../../images/pointing-hand.png") 0 50%/contain no-repeat;
  }
}
@media screen and (min-width: 1220px) {
  .module-newslist .content-cta {
    padding: 90px;
  }
}
.module-newsreader {
  margin-top: 20px;
}
.module-newsreader__category {
  display: inline-block;
  background: #000;
  color: #fff;
  margin-right: 50px;
  padding: 11px 24px;
  border-radius: 100px;
}
.module-newsreader__meta {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.module-newsreader__author {
  margin-left: 6px;
}
.module-newsreader__share-action {
  margin: 1em 0;
  font-weight: 700;
  text-decoration: underline;
  background: none;
  border: none;
  cursor: pointer;
}
.module-newsreader__share li button {
  background: none;
  border: none;
  cursor: pointer;
}
.module-newsreader__image {
  margin-bottom: 50px;
}
.module-newsreader__image img {
  width: 100%;
}
.module-newsreader__teaser {
  font-size: 22px;
  line-height: 30px;
}
.module-newsreader__text > :first-child p:first-child:first-letter {
  float: left;
  color: #F8A700;
  font-size: 50px;
  font-weight: 700;
  margin-top: 10px;
  margin-right: 5px;
}
.module-newsreader + .mod_newscategories h2 {
  display: none;
}
.module-newsreader + .mod_newscategories + .content-text {
  padding-top: 0;
  padding-bottom: 0;
}

@media screen and (max-width: 767px) {
  .module-newsreader__share-overlay {
    position: fixed;
    inset: 0;
    background: transparent;
    pointer-events: none;
    z-index: 800;
    transition: background 0.1s ease-in-out;
  }
  .module-newsreader__share-close {
    display: block;
    float: right;
    width: 40px;
    height: 40px;
    background: url("../../images/minus.svg") 100% 5px/20px 20px no-repeat;
    border: none;
    cursor: pointer;
  }
  .module-newsreader__share-items {
    position: absolute;
    inset: auto 0 0 0;
    padding: 15px 20px;
    background: #fff;
    box-shadow: 0 0 10px 3px rgba(221, 239, 255, 0.8);
    pointer-events: none;
    transition: transform 0.3s ease-in-out;
    transform: translateY(100%);
    z-index: 800;
  }
  .module-newsreader__share ul {
    clear: both;
  }
  .module-newsreader__share li {
    padding: 5px;
    font-size: 14px;
    line-height: 22px;
    white-space: nowrap;
  }
  .module-newsreader__share li a, .module-newsreader__share li button {
    display: block;
    width: 100%;
    text-align: center;
  }
  body.news-share {
    overflow: hidden;
  }
  body.news-share .module-newsreader__share-overlay {
    background: rgba(0, 0, 0, 0.2);
  }
  body.news-share .module-newsreader__share-items {
    transform: translateY(0%);
    pointer-events: auto;
  }
}
@media screen and (min-width: 768px) {
  .module-newsreader {
    margin-top: 60px;
  }
  .module-newsreader__header {
    display: flex;
    align-items: center;
  }
  .module-newsreader__categories {
    flex-shrink: 0;
  }
  .module-newsreader__share {
    position: relative;
  }
  .module-newsreader__share-close {
    display: none;
  }
  .module-newsreader__share-items {
    position: absolute;
    right: 0;
    padding: 15px 20px;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 0 10px 3px rgba(221, 239, 255, 0.8);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.1s ease-in-out;
    z-index: 10;
  }
  .module-newsreader__share li {
    padding: 5px;
    font-size: 14px;
    line-height: 22px;
    white-space: nowrap;
  }
  .module-newsreader__share:hover .module-newsreader__share-items, body.news-share .module-newsreader__share .module-newsreader__share-items {
    opacity: 1;
    pointer-events: auto;
  }
  .module-newsreader__image {
    margin-top: 70px;
    margin-bottom: 50px;
  }
  .module-newsreader + .mod_newscategories + .content-text {
    margin: -60px 0 -40px;
  }
}
@media screen and (min-width: 1220px) {
  .module-newsreader__headline, .module-newsreader__teaser {
    max-width: 83.3333333333%;
  }
}
.mod_newscategories {
  margin: 60px 0;
}
.mod_newscategories ul {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 0;
  padding: 0;
  list-style: none;
}
.mod_newscategories li {
  margin: 0;
  padding: 0;
}
.mod_newscategories a,
.mod_newscategories strong {
  display: inline-block;
  background: #fff;
  padding: 11px 24px;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  border: 2px solid #000;
  border-radius: 100px;
  transition: all 0.1s ease-in-out;
}
.mod_newscategories strong,
.mod_newscategories a:hover,
.mod_newscategories a.news_trail {
  color: #fff;
  background: #000;
}

@media screen and (min-width: 768px) {
  .mod_newscategories {
    display: flex;
    align-items: center;
    margin: 100px 0 80px;
  }
  .mod_newscategories h2 {
    margin: 0 18px 0 0;
    font-size: 18px;
    line-height: 26px;
  }
  .mod_newscategories ul {
    gap: 30px;
  }
}
.ce_accordion {
  margin: 0 0 30px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px 3px rgba(221, 239, 255, 0.8);
}
.ce_accordion .toggler {
  margin: 0;
  padding: 20px;
}
.ce_accordion .toggler button {
  position: relative;
  display: block;
  width: 100%;
  padding: 0 30px 0 0;
  margin: 0;
  background: none;
  border: none;
  text-align: start;
  cursor: pointer;
}
.ce_accordion .toggler button:after {
  content: "";
  position: absolute;
  top: 18px;
  right: 20px;
  display: block;
  width: 22px;
  height: 22px;
  background: url("../../images/chevron.svg") 0 0/contain no-repeat;
  transform: scaleY(-1);
  transition: transform 0.2s ease-in-out;
}
.ce_accordion .toggler.active button:after {
  transform: rotateZ(0);
}
.ce_accordion .accordion {
  padding: 0 20px 20px;
}
.ce_accordion h1.toggler + .accordion > div > .content-abstract:first-child p {
  font-size: 22px;
  line-height: 30px;
}
.ce_accordion.handorgel .toggler {
  padding: 0;
}
.ce_accordion.handorgel .toggler button {
  padding: 20px 50px 20px 20px;
}

@media screen and (min-width: 768px) {
  .ce_accordion .toggler button:after {
    top: 28px;
    right: 30px;
  }
  .ce_accordion .accordion {
    padding: 0 30px 30px;
  }
  .ce_accordion .accordion p:first-child {
    margin-top: 0;
  }
  .ce_accordion .toggler, .ce_accordion.handorgel .toggler button {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .ce_accordion .accordion,
  .ce_accordion .toggler, .ce_accordion.handorgel .toggler button {
    padding-left: 8.3333333333%;
    padding-right: 16.6666666667%;
  }
}
.content-apps {
  margin-top: 50px;
  margin-bottom: 30px;
}
.content-apps__apps {
  display: flex;
  margin: 20px 0 30px;
}
.content-apps__app {
  width: 160px;
  height: 46px;
  margin-right: 20px;
}

@media screen and (min-width: 768px) {
  .content-apps {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: 100px;
  }
  .content-apps > * {
    flex-grow: 1;
    padding-left: 10px;
    padding-right: 10px;
  }
  .content-apps__content {
    padding-left: 10px;
    padding-right: 10px;
    width: 58.3333333333%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .content-apps__headline {
    margin-bottom: 10px;
  }
  .content-apps__image {
    padding-left: 10px;
    padding-right: 10px;
    width: 41.6666666667%;
    text-align: center;
  }
}
.content-benefits__headline {
  text-align: center;
}
.content-benefits__benefits {
  margin: 0 -20px 70px;
}
.content-benefits__benefit {
  padding: 20px;
}
.content-benefits__benefit-inside {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px 3px rgba(221, 239, 255, 0.8);
}
.content-benefits .tns-nav {
  display: flex;
  justify-content: center;
}
.content-benefits .tns-nav button {
  margin: 0 3px;
  width: 9px;
  height: 9px;
  background: #F8A700;
  border: none;
  border-radius: 50%;
  opacity: 0.3;
}
.content-benefits .tns-nav button.tns-nav-active {
  opacity: 1;
}
.content-benefits__summary {
  margin: 0 -20px;
  padding: 30px 20px;
  background: #DDEFFF;
}
.content-benefits__summary-inside {
  position: relative;
  padding: 30px;
  text-align: center;
  background: #fff;
  border-radius: none;
  box-shadow: 0 0 10px 3px rgba(221, 239, 255, 0.8);
}
.content-benefits__summary-inside:before {
  content: "";
  position: absolute;
  top: -20px;
  left: calc(50% - 15px);
  display: block;
  width: 30px;
  height: 30px;
  background: #DDEFFF;
  transform: rotateZ(45deg);
}

@media screen and (min-width: 768px) {
  .content-benefits {
    margin-bottom: -130px;
  }
  .content-benefits__background {
    position: relative;
    padding-top: 100px;
    padding-bottom: 200px;
  }
  .content-benefits__background:before {
    content: "";
    position: absolute;
    left: -20px;
    right: -20px;
    top: 0;
    bottom: 0;
    background: #DDEFFF;
    z-index: -1;
  }
}
@media screen and (min-width: 768px) and (min-width: 768px) {
  .content-benefits__background:before {
    left: -30px;
    right: -30px;
  }
}
@media screen and (min-width: 768px) and (min-width: 1220px) {
  .content-benefits__background:before {
    left: calc(-50vw + 590px);
    right: calc(-50vw + 590px);
  }
}
@media screen and (min-width: 768px) {
  .content-benefits__benefits,
  .content-benefits #tns1 {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
  }
  .content-benefits__benefits > *,
  .content-benefits #tns1 > * {
    flex-grow: 1;
    padding-left: 10px;
    padding-right: 10px;
  }
  .content-benefits__benefits .tns-outer,
  .content-benefits #tns1 .tns-outer {
    padding: 0 10px !important;
  }
  .content-benefits__benefit {
    padding-left: 10px;
    padding-right: 10px;
    width: 33.3333333333%;
  }
  .content-benefits__benefit-inside {
    padding: 0;
    text-align: center;
    background: none;
    box-shadow: none;
  }
  .content-benefits__summary {
    background: none;
    position: relative;
    top: -200px;
    padding-left: 10px;
    padding-right: 10px;
    width: 83.3333333333%;
    margin-left: 8.3333333333%;
    background: #fff;
    border-radius: none;
    box-shadow: 0 0 10px 3px rgba(221, 239, 255, 0.8);
  }
  .content-benefits__summary-inside {
    position: static;
    padding-left: 10px;
    padding-right: 10px;
    width: 80%;
    margin-left: 10%;
    font-size: 22px;
    line-height: 30px;
    font-weight: 700;
    box-shadow: none;
  }
}
.content-contact {
  position: relative;
  padding: 28px 0 70px;
}
.content-contact--secondary {
  margin-top: 100px;
  position: relative;
}
.content-contact--secondary:before {
  content: "";
  position: absolute;
  left: -20px;
  right: -20px;
  top: 0;
  bottom: 0;
  background: #DDEFFF;
  z-index: -1;
}
@media screen and (min-width: 768px) {
  .content-contact--secondary:before {
    left: -30px;
    right: -30px;
  }
}
@media screen and (min-width: 1220px) {
  .content-contact--secondary:before {
    left: calc(-50vw + 590px);
    right: calc(-50vw + 590px);
  }
}
.content-contact__person {
  width: 50%;
}
.content-contact__image {
  max-width: 220px;
  border: 4px solid #fff;
}
.content-contact__name {
  margin: 8px 0 4px;
}
.content-contact__position {
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .content-contact {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    padding-top: 70px;
  }
  .content-contact > * {
    flex-grow: 1;
    padding-left: 10px;
    padding-right: 10px;
  }
  .content-contact__person {
    padding-left: 10px;
    padding-right: 10px;
    width: 25%;
    margin-left: 8.3333333333%;
  }
  .content-contact__text {
    padding-left: 10px;
    padding-right: 10px;
    width: 58.3333333333%;
  }
}
.cta, .formbody .widget-submit button {
  display: inline-block;
  margin-top: 2em;
  padding: 14px 32px;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  text-decoration: none;
  background: #000;
  border: 2px solid #000;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
}
.cta:hover, .formbody .widget-submit button:hover, .cta:active, .formbody .widget-submit button:active {
  background-color: #F8A700;
  border-color: #F8A700;
}
.cta--secondary {
  color: #000;
  background: transparent;
}
.cta--secondary:hover, .cta--secondary:active {
  color: #fff;
  background-color: #F8A700;
  border-color: #F8A700;
}
.cta:disabled, .formbody .widget-submit button:disabled, .cta.disabled, .formbody .widget-submit button.disabled {
  color: #fff !important;
  background-color: #8D8D8D !important;
  border-color: #8D8D8D !important;
  cursor: not-allowed;
  pointer-events: none;
}
.cta--tag {
  background: #fff;
}
.cta--tag:hover, .cta--tag:active {
  background-color: #F8A700;
  border-color: #F8A700;
}
.cta--tag:active {
  background-color: #000;
}
.cta.content-hyperlink, .formbody .widget-submit button.content-hyperlink {
  padding: 0;
}
.cta.content-hyperlink > a, .formbody .widget-submit button.content-hyperlink > a {
  display: block;
  padding: 14px 32px;
}
.cta__dialog {
  height: 100%;
  width: 100%;
  max-width: none;
  max-height: none;
  margin: 0;
  padding: 0;
  border: none;
  transition: transform 0.3s ease-in-out;
  transform: translateY(100vh);
  pointer-events: none;
}
.cta__dialog::backdrop {
  background: transparent;
}
.cta__dialog--active {
  transform: translateY(0);
  pointer-events: auto;
}
.cta__links {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 2em;
}
.cta__links .cta, .cta__links .formbody .widget-submit button, .formbody .widget-submit .cta__links button {
  margin-top: 0;
}
.cta__form {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 60px 10px 50px;
  background: #DDEFFF;
}
.cta__close {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  width: 64px;
  height: 64px;
  background: url("../../images/close.svg") center center/24px 24px no-repeat;
  border: none;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .cta__dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.9);
    padding: 50px;
    transform: none;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
  }
  .cta__dialog--active {
    opacity: 1;
  }
  .cta__form {
    max-width: 980px;
    max-height: calc(100vh - 2em);
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 0 8px 14px -2px rgba(128, 176, 214, 0.8);
  }
  .cta__close {
    top: 10px;
    right: 10px;
  }
}
@media screen and (min-width: 1220px) {
  .cta__form {
    padding-left: 90px;
    padding-right: 90px;
  }
}
.content-cta {
  padding: 40px 0;
  position: relative;
}
.content-cta:before {
  content: "";
  position: absolute;
  left: -20px;
  right: -20px;
  top: 0;
  bottom: 0;
  background: #F8A700;
  z-index: -1;
}
@media screen and (min-width: 768px) {
  .content-cta:before {
    left: -30px;
    right: -30px;
  }
}
@media screen and (min-width: 1220px) {
  .content-cta:before {
    left: calc(-50vw + 590px);
    right: calc(-50vw + 590px);
  }
}
.mod_article > .inside > .content-cta:last-child {
  margin-bottom: -60px;
}
.content-cta__link {
  margin-top: 0;
}
.content-cta__link:hover {
  color: #000;
  border-color: #000;
}
.content-cta__link.cta--secondary:hover {
  color: #fff;
  border-color: #fff;
}

@media screen and (min-width: 768px) {
  .content-cta {
    margin-top: 60px;
    padding: 60px 10% 80px 30%;
    background: url("../../images/pointing-hand.png") 14% 90px/15% auto no-repeat;
  }
  .content-text--wave-start + .content-cta, .content-text--wave-stop + .content-cta, .content-text--wave-top + .content-cta, .content-values + .content-cta {
    margin-top: 0;
  }
}
.content-downloads {
  margin-bottom: 70px;
}
.content-downloads li {
  background-image: url("../../images/download.svg");
}
.content-downloads .download-element a {
  display: block;
  text-decoration: underline;
}
.content-downloads .download-element a:hover {
  text-decoration: none;
}

@media screen and (min-width: 768px) {
  .content-downloads {
    margin-bottom: 100px;
  }
}
.content-features {
  position: relative;
  padding-top: 110px;
  padding-bottom: 70px;
  position: relative;
}
.content-features:before {
  content: "";
  position: absolute;
  left: -20px;
  right: -20px;
  top: 0;
  bottom: 0;
  background: #DDEFFF;
  z-index: -1;
}
@media screen and (min-width: 768px) {
  .content-features:before {
    left: -30px;
    right: -30px;
  }
}
@media screen and (min-width: 1220px) {
  .content-features:before {
    left: calc(-50vw + 590px);
    right: calc(-50vw + 590px);
  }
}
.content-features__current {
  position: absolute;
  z-index: 10;
  top: 5px;
  left: 0;
  width: 160px;
  height: 150px;
  background: url("../../images/hearthand.png") 0 0/contain no-repeat;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}
.content-features__current-title {
  position: absolute;
  top: 76px;
  left: 23px;
  display: block;
  width: 109px;
  transform: rotateZ(-10deg);
}
.content-features__current-counter {
  position: absolute;
  top: 105px;
  left: 33px;
  display: block;
  width: 62px;
  transform: rotateZ(-19deg);
}
.content-features__image {
  margin-left: 60px;
}
.content-features__headline {
  margin-top: 20px;
}
.content-features__links {
  margin-right: 150px;
}
.content-features .tns-controls {
  position: absolute;
  bottom: 82px;
  right: 20px;
  display: flex;
  justify-content: space-between;
}
.content-features .tns-controls button {
  border: none;
}
.content-features .tns-controls button[data-controls=prev] {
  padding-left: 30px;
  background: transparent url("../../images/slider--prev.svg") 0 50%/24px auto no-repeat;
  text-indent: -999em;
}
.content-features .tns-controls button[data-controls=next] {
  padding-right: 30px;
  background: transparent url("../../images/slider--next.svg") 100% 50%/24px auto no-repeat;
  text-indent: -999em;
}
.content-features .tns-controls button:disabled {
  opacity: 0.15;
  cursor: not-allowed;
}

@media screen and (min-width: 768px) {
  .content-features {
    padding-top: 230px;
  }
  .content-features__current {
    top: 30px;
    left: 0;
    width: 200px;
    height: 180px;
    font-size: 23px;
    line-height: 26px;
  }
  .content-features__current-title {
    top: 95px;
    left: 28px;
    width: 129px;
  }
  .content-features__current-counter {
    top: 130px;
    left: 40px;
    width: 72px;
  }
  .content-features__feature-inside {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    flex-direction: row-reverse;
  }
  .content-features__feature-inside > * {
    flex-grow: 1;
    padding-left: 10px;
    padding-right: 10px;
  }
  .content-features__image {
    padding-left: 10px;
    padding-right: 10px;
    width: 50%;
    margin-left: 0;
  }
  .content-features__content {
    padding-left: 10px;
    padding-right: 10px;
    width: 50%;
  }
  .content-features__links {
    margin-right: 0;
  }
}
@media screen and (min-width: 1400px) {
  .content-features .tns-controls {
    top: calc(50% - 15px);
    bottom: auto;
    left: -80px;
    right: -80px;
  }
}
.ce_form {
  padding: 20px 10px;
  background: #DDEFFF;
}

.content-gallery {
  margin: 80px 0 35px;
}
.content-gallery ul {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}
.content-gallery ul > * {
  flex-grow: 1;
  padding-left: 10px;
  padding-right: 10px;
}
.content-gallery li {
  padding-left: 10px;
  padding-right: 10px;
  width: 25%;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}
.content-gallery img {
  width: auto;
  max-height: 50px;
}

@media screen and (min-width: 768px) {
  .content-gallery {
    margin: 120px 0 100px;
  }
  .content-gallery li {
    padding-left: 10px;
    padding-right: 10px;
    width: 16.6666666667%;
  }
}
@media screen and (min-width: 768px) {
  .ce_rsce_grid-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
  }
  .ce_rsce_grid-row > * {
    flex-grow: 1;
    padding-left: 10px;
    padding-right: 10px;
  }
  .ce_rsce_grid-row--md3 > * {
    padding-left: 10px;
    padding-right: 10px;
    width: 25%;
  }
  .ce_rsce_grid-row--offset-md3 > * {
    margin-left: 25%;
  }
  .ce_rsce_grid-row--md4 > * {
    padding-left: 10px;
    padding-right: 10px;
    width: 33.3333333333%;
  }
  .ce_rsce_grid-row--offset-md4 > * {
    margin-left: 33.3333333333%;
  }
  .ce_rsce_grid-row--md6 > * {
    padding-left: 10px;
    padding-right: 10px;
    width: 50%;
  }
  .ce_rsce_grid-row--offset-md6 > * {
    margin-left: 50%;
  }
  .ce_rsce_grid-row--secondary {
    padding: 100px 0 70px;
    position: relative;
  }
  .ce_rsce_grid-row--secondary:before {
    content: "";
    position: absolute;
    left: -20px;
    right: -20px;
    top: 0;
    bottom: 0;
    background: #DDEFFF;
    z-index: -1;
  }
}
@media screen and (min-width: 768px) and (min-width: 768px) {
  .ce_rsce_grid-row--secondary:before {
    left: -30px;
    right: -30px;
  }
}
@media screen and (min-width: 768px) and (min-width: 1220px) {
  .ce_rsce_grid-row--secondary:before {
    left: calc(-50vw + 590px);
    right: calc(-50vw + 590px);
  }
}
@media screen and (min-width: 1220px) {
  .ce_rsce_grid-row--lg3 > * {
    padding-left: 10px;
    padding-right: 10px;
    width: 25%;
  }
  .ce_rsce_grid-row--offset-lg3 > * {
    margin-left: 25%;
  }
  .ce_rsce_grid-row--lg4 > * {
    padding-left: 10px;
    padding-right: 10px;
    width: 33.3333333333%;
  }
  .ce_rsce_grid-row--offset-lg4 > * {
    margin-left: 33.3333333333%;
  }
  .ce_rsce_grid-row--lg6 > * {
    padding-left: 10px;
    padding-right: 10px;
    width: 50%;
  }
  .ce_rsce_grid-row--offset-lg6 > * {
    margin-left: 50%;
  }
}
.content-logos {
  overflow: hidden;
  margin: 60px 0;
}
.content-logos__wrapper {
  position: relative;
  display: flex;
}
.content-logos__wrapper:before, .content-logos__wrapper:after {
  content: "";
  position: absolute;
  z-index: 2;
  top: 0;
  width: 200px;
  height: 100%;
  background: linear-gradient(to right, rgb(255, 255, 255), rgba(255, 255, 255, 0));
}
.content-logos__wrapper:before {
  left: 0;
}
.content-logos__wrapper:after {
  right: 0;
  transform: rotateZ(180deg);
}
.content-logos__container {
  display: flex;
  flex-shrink: 0;
}
.content-logos__items {
  flex-shrink: 0;
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  animation: marquee 60s linear infinite;
}
@media (prefers-reduced-motion) {
  .content-logos__items {
    animation: none !important;
  }
}
.content-logos__item {
  display: inline-block;
  padding: 10px 40px;
}
.content-logos__item img {
  height: 100px;
  width: auto;
}

@media screen and (min-width: 768px) {
  .content-logos {
    margin-bottom: 100px;
  }
}
@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.content-mission {
  position: relative;
  overflow: hidden;
  margin: 0 -20px;
  padding: 90px 20px 75vw;
  font-weight: 700;
}
.content-mission::before {
  content: "";
  position: absolute;
  inset: 30px auto auto 20px;
  display: block;
  width: 48px;
  height: 48px;
  background: url("../../images/quote.svg") 0 0/contain no-repeat;
}
.content-mission__image {
  position: absolute;
  inset: auto 0 0;
  z-index: -1;
}
.content-mission__image-desktop {
  display: none;
}
.content-mission__dateline {
  margin: 0;
  font-size: 22px;
  line-height: 30px;
}
.content-mission__text {
  margin: 15px 0 0;
  font-size: 30px;
  line-height: 38px;
}

@media screen and (min-width: 768px) {
  .content-mission {
    margin: 0 -30px;
    padding: 120px 30px 500px;
  }
  .content-mission:before {
    top: 60px;
    left: 30px;
  }
  .content-mission__image {
    width: 4000px;
    inset: auto 0 0 calc(-2000px + 20vw);
  }
  .content-mission__image-desktop {
    display: block;
  }
  .content-mission__image-mobile {
    display: none;
  }
}
@media screen and (min-width: 1220px) {
  .content-mission {
    display: flex;
    margin: 0;
    height: 900px;
    padding: 160px 0 0;
    overflow: visible;
  }
  .content-mission:before {
    top: 100px;
    left: 0;
  }
  .content-mission__content {
    width: 45%;
  }
  .content-mission__text {
    font-size: 48px;
    line-height: 56px;
  }
  .content-mission__image {
    inset: 0 0 0 -1390px;
  }
}
.ce_rsce_offer {
  position: relative;
  margin: 0 0 60px;
  padding-bottom: 20px;
  font-size: 14px;
  line-height: 22px;
}
.ce_rsce_offer:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  width: 25%;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px 3px rgba(221, 239, 255, 0.8);
  background: url("../../images/offer.svg") 0 0/contain no-repeat;
  z-index: -1;
}
.ce_rsce_offer table {
  width: 100%;
  border-collapse: collapse;
}
.ce_rsce_offer th, .ce_rsce_offer td {
  height: 50px;
  padding: 5px 0;
}
.ce_rsce_offer th {
  padding-bottom: 10%;
  font-weight: 700;
}
.ce_rsce_offer td {
  border-top: 2px solid #DDEFFF;
  border-bottom: 2px solid #DDEFFF;
  vertical-align: middle;
}
.ce_rsce_offer tr:first-child td {
  border-top: none;
}
.ce_rsce_offer__text {
  font-size: 14px;
  line-height: 22px;
  width: 50%;
  padding-right: 10px !important;
}
.ce_rsce_offer__col {
  width: 25%;
  text-align: center;
}
.ce_rsce_offer__col span {
  margin-right: 5px;
  color: #F8A700;
}
.ce_rsce_offer__state {
  display: inline-block;
  width: 24px;
  height: 24px;
  text-indent: -999em;
}
.ce_rsce_offer__state--yes {
  background: url("../../images/state--yes.svg") center center no-repeat;
}
.ce_rsce_offer__state--no {
  background: url("../../images/state--no.svg") center center no-repeat;
}

@media screen and (min-width: 580px) {
  .ce_rsce_offer th {
    padding-top: 12px;
  }
}
@media screen and (min-width: 768px) {
  .ce_rsce_offer {
    width: 75%;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 140px;
  }
  .ce_rsce_offer th,
  .ce_rsce_offer td {
    font-size: 18px;
    line-height: 26px;
    font-weight: 700;
  }
  .ce_rsce_offer td {
    height: 70px;
  }
  .ce_rsce_offer__text {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .ce_rsce_offer__state {
    width: 30px;
    height: 30px;
  }
}
@media screen and (min-width: 1220px) {
  .ce_rsce_offer th {
    padding-top: 20px;
  }
}
.ce_rsce_partner {
  padding: 50px 0;
  position: relative;
}
.ce_rsce_partner:before {
  content: "";
  position: absolute;
  left: -20px;
  right: -20px;
  top: 0;
  bottom: 0;
  background: #DDEFFF;
  z-index: -1;
}
@media screen and (min-width: 768px) {
  .ce_rsce_partner:before {
    left: -30px;
    right: -30px;
  }
}
@media screen and (min-width: 1220px) {
  .ce_rsce_partner:before {
    left: calc(-50vw + 590px);
    right: calc(-50vw + 590px);
  }
}
.ce_rsce_partner__headline {
  margin-bottom: 10px;
}
.ce_rsce_partner__text {
  margin-bottom: 50px;
  font-size: 22px;
  line-height: 30px;
}
.ce_rsce_partner__text p:first-child {
  margin-top: 0;
}
.ce_rsce_partner__partners {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.ce_rsce_partner__partner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vw - 40px);
  max-height: 380px;
  padding: 50px 30px 50px 30px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px 3px rgba(208, 224, 240, 0.8);
  cursor: pointer;
}
.ce_rsce_partner__toggle {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 15;
  width: 30px;
  height: 30px;
  background: #DDEFFF;
  border: none;
  text-indent: -999em;
  cursor: pointer;
}
.ce_rsce_partner__toggle:after {
  content: "";
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 16;
  width: 14px;
  height: 14px;
  background: url("../../images/plus--black.svg") 0 0/contain no-repeat;
  transition: transform 0.2s ease-in-out;
}
.ce_rsce_partner__content {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  padding: 50px 30px;
  font-size: 16px;
  line-height: 22px;
  background: #fff;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.ce_rsce_partner__content h3 {
  margin-top: 0;
  text-decoration: underline;
  font-size: inherit;
}
.ce_rsce_partner__content p:last-child {
  margin-bottom: 0;
}
.ce_rsce_partner__partner--details {
  cursor: auto;
}
.ce_rsce_partner__partner--details .ce_rsce_partner__toggle:after {
  transform: rotateZ(45deg);
}
.ce_rsce_partner__partner--details .ce_rsce_partner__content {
  opacity: 1;
}

@media screen and (min-width: 768px) {
  .ce_rsce_partner {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .ce_rsce_partner__text {
    max-width: 880px;
  }
  .ce_rsce_partner__partner {
    width: calc(50% - 10px);
  }
}
@media screen and (min-width: 1220px) {
  .ce_rsce_partner__partner {
    width: calc(33.3333333% - 15px);
  }
}
.content-quote {
  position: relative;
}
.content-quote__item {
  padding-top: 150px;
  text-align: center;
  background: url("../../images/quote.svg") center 60px/48px 48px no-repeat;
}
.content-quote__name {
  position: relative;
  margin-top: 40px;
  padding-top: 20px;
  font-size: 14px;
  line-height: 22px;
}
.content-quote__name:before {
  position: absolute;
  left: 50%;
  top: 0;
  content: "—";
}
.content-quote__company {
  display: block;
}
.content-quote__navigation {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.content-quote__navigation > * {
  display: none;
  cursor: pointer;
  padding: 0 40px;
  width: 180px;
  height: 100px;
}
.content-quote__navigation > *.tns-nav-active {
  display: block;
}
.content-quote__navigation img {
  width: 100%;
}
.content-quote .tns-controls {
  position: absolute;
  bottom: 0;
  left: 20px;
  right: 20px;
  display: flex;
  justify-content: space-between;
}
.content-quote .tns-controls button {
  width: 60px;
  height: 100px;
  border: none;
  text-indent: -999em;
}
.content-quote .tns-controls button[data-controls=prev] {
  background: transparent url("../../images/slider--prev.svg") 0 50%/24px auto no-repeat;
}
.content-quote .tns-controls button[data-controls=next] {
  background: transparent url("../../images/slider--next.svg") 100% 50%/24px auto no-repeat;
}
.content-quote .tns-controls button:disabled {
  opacity: 0.15;
  cursor: not-allowed;
}

@media screen and (min-width: 768px) {
  .content-quote__text, .content-quote__name {
    padding-left: 10px;
    padding-right: 10px;
    width: 83.3333333333%;
    margin-left: 8.3333333333%;
  }
  .content-quote__name {
    margin-top: 30px;
    padding-top: 30px;
  }
  .content-quote__company {
    display: inline;
  }
  .content-quote__navigation > * {
    display: block;
    opacity: 0.15;
  }
  .content-quote__navigation > *.tns-nav-active {
    opacity: 1;
  }
}
.content-steps {
  margin-bottom: 70px;
}
.content-steps__step {
  margin: 20px auto;
  padding: 30px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px 3px rgba(221, 239, 255, 0.8);
}
.content-steps__index {
  font-size: 80px;
  line-height: 88px;
  font-weight: 700;
  color: #F8A700;
}

@media screen and (min-width: 768px) {
  .content-steps {
    margin-bottom: 100px;
  }
  .content-steps__step {
    display: flex;
    width: 83.3333333333%;
    margin: 20px auto;
    padding: 30px 0;
  }
  .content-steps__index {
    width: calc(20% - 20px);
    padding-left: 30px;
    flex-shrink: 0;
  }
  .content-steps__content {
    padding-right: 10%;
  }
  .content-steps__content h2 {
    margin-top: 10px;
  }
}
.content-team {
  margin-bottom: 70px;
}
.content-team__people {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.content-team__people > * {
  flex-grow: 1;
  padding-left: 10px;
  padding-right: 10px;
}
.content-team__person {
  display: flex;
  width: 280px;
  flex-shrink: 0;
}
.content-team__image {
  position: relative;
}
.content-team__image a {
  position: absolute;
  display: block;
  bottom: 0;
  right: 0;
  width: 30px;
  height: 30px;
  background: #000 url("../../images/email.svg") center center/12px 8px no-repeat;
  text-indent: -999em;
}
.content-team__name {
  margin: 20px 0 0 11px;
  font-size: 22px;
  line-height: 30px;
}
.content-team__title {
  margin-left: 11px;
  color: #8D8D8D;
}
.content-team--text {
  padding-top: 50px;
  padding-bottom: 70px;
  text-align: center;
  position: relative;
}
.content-team--text:before {
  content: "";
  position: absolute;
  left: -20px;
  right: -20px;
  top: 0;
  bottom: 0;
  background: #DDEFFF;
  z-index: -1;
}
@media screen and (min-width: 768px) {
  .content-team--text:before {
    left: -30px;
    right: -30px;
  }
}
@media screen and (min-width: 1220px) {
  .content-team--text:before {
    left: calc(-50vw + 590px);
    right: calc(-50vw + 590px);
  }
}
.content-team--text .content-team__text {
  font-size: 22px;
  line-height: 30px;
  margin-top: -20px;
  margin-bottom: 30px;
}
.content-team--text .content-team__person-inside {
  padding-bottom: 30px;
  background: #fff;
  text-align: start;
}
.content-team--text .content-team__image {
  padding-top: 11px;
  margin: 0 11px;
}

@media screen and (min-width: 768px) {
  .content-team__people {
    flex-wrap: wrap;
    overflow: visible;
  }
  .content-team__person {
    padding-left: 10px;
    padding-right: 10px;
    width: 25%;
    margin-bottom: 50px;
  }
  .content-team--text .content-team__inside {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
  }
  .content-team--text .content-team__inside > * {
    flex-grow: 1;
    padding-left: 10px;
    padding-right: 10px;
  }
  .content-team--text .content-team__headline, .content-team--text .content-team__text {
    padding-left: 10px;
    padding-right: 10px;
    width: 83.3333333333%;
    margin-left: 8.3333333333%;
  }
  .content-team--text .content-team__people {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    justify-content: center;
  }
}
.content-testimonial {
  margin: 40px -20px;
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px 3px rgba(221, 239, 255, 0.8);
}
.content-testimonial__image {
  margin-bottom: 25px;
}
.content-testimonial__image img {
  width: 225px;
}
.content-testimonial__content {
  padding-top: 70px;
  background: url("../../images/quote.svg") 0 5px/auto 34px no-repeat;
}
.content-testimonial__text {
  margin-top: 0;
}
.content-testimonial__name, .content-testimonial__company {
  font-size: 14px;
  line-height: 22px;
}
.content-testimonial__name {
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .content-testimonial {
    display: flex;
    margin: 0;
  }
  .content-testimonial__image {
    width: calc((100% - 20px) / 4);
    flex-shrink: 0;
  }
  .content-testimonial__content {
    padding-right: calc(11.1111111111% - 50px);
  }
  .content-testimonial + .content-testimonial {
    margin-top: 30px;
  }
}
.content-text {
  padding: 60px 0;
}
.content-text--headline-h2 .content-text__dateline {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
}
.content-text__headline {
  margin-top: 0;
}
.content-text__text p + h2 {
  margin-top: 1em;
}
.content-text__text p:first-child {
  margin-top: 0;
}
.content-text__text p:last-child {
  margin-bottom: 0;
}
.content-text__links {
  margin-top: 3em;
}
.content-text__headline + .content-text__links {
  margin-top: 2em;
}
.content-text--list-water li {
  background-image: url("../../images/checkmark--water.svg");
}
.content-text--list-power li {
  background-image: url("../../images/checkmark--power.svg");
}
.content-text--headline-h1 .content-text__text p, .content-text--font-large .content-text__text p {
  font-size: 22px;
  line-height: 30px;
}
.content-text--font-regular .content-text__text p {
  font-size: 18px;
  line-height: 26px;
}
.content-text--headline-h1 {
  padding-bottom: 70px;
}
.content-text--headline-h1 + .content-text--plain {
  margin-top: -70px;
  padding-bottom: 70px !important;
}
.content-text--no-text h1 {
  margin-bottom: 0;
}
.content-text--secondary + .content-text--plain {
  margin-top: 0;
  padding-top: 70px;
}
.content-text--image-left .content-text__image, .content-text--image-right .content-text__image {
  margin: 0 -20px 30px;
  text-align: center;
}
.content-text--image-background .content-text__image, .content-text--image-splash .content-text__image {
  display: none;
}
.content-text--image-homepage {
  padding-bottom: 0;
}
.content-text--image-homepage .content-text__inside {
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 340px;
}
.content-text--image-homepage .content-text__image {
  margin: 20px -20px 0;
}
.content-text--image-homepage .content-text__image--animation {
  position: absolute;
  inset: auto 0 0;
  z-index: -1;
}
.content-text--image-homepage .content-text__image--animation-desktop {
  display: none;
}
.content-headline + .content-text, .content-text--plain + .content-text--plain {
  padding-top: 20px;
  padding-bottom: 20px;
}

@media screen and (min-width: 600px) {
  .content-text--image-homepage {
    padding-bottom: unset;
  }
  .content-text--image-homepage .content-text__inside {
    display: block;
    padding-bottom: 0;
  }
  .content-text--image-homepage .content-text__image {
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: absolute;
    inset: 0 -20px;
    z-index: -1;
    padding: 0;
    margin: 0;
    text-align: center;
  }
  .content-text--image-homepage .content-text__image--animation-desktop {
    display: block;
  }
  .content-text--image-homepage .content-text__image--animation-mobile {
    display: none;
  }
  .content-text--image-homepage .content-text__image video,
  .content-text--image-homepage .content-text__image img {
    object-fit: cover;
    height: 100%;
  }
  .content-text--image-homepage .content-text__content {
    width: 600px;
    max-width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 380px;
  }
}
@media screen and (min-width: 768px) {
  .content-text {
    position: relative;
    padding-bottom: 100px;
  }
  .content-text__inside {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    align-items: center;
  }
  .content-text__inside > * {
    flex-grow: 1;
    padding-left: 10px;
    padding-right: 10px;
  }
  .content-text--headline-h1 + .content-text--plain {
    margin-top: -80px;
    padding-bottom: 100px !important;
  }
  .content-text--headline-h1 + .mod_search {
    margin-top: -50px;
    position: relative;
    z-index: 10;
  }
  .content-text--no-text {
    padding-bottom: 60px;
  }
  .content-text--no-text + .content-text {
    margin-top: -40px;
  }
  .content-text--secondary + .content-text--plain {
    margin-top: 0;
    padding-top: 100px;
  }
  .content-text--image-left .content-text__image, .content-text--image-right .content-text__image {
    padding-left: 10px;
    padding-right: 10px;
    width: 50%;
    margin-left: 0;
    margin-right: 0;
  }
  .content-text--image-left .content-text__image--animation, .content-text--image-right .content-text__image--animation {
    width: 100%;
    margin: -60px -25%;
    z-index: -1;
  }
  .content-text--image-left .content-text__content, .content-text--image-right .content-text__content {
    padding-left: 10px;
    padding-right: 10px;
    width: 50%;
  }
  .content-text--image-right .content-text__inside {
    flex-direction: row-reverse;
  }
  .content-text--image-background .content-text__image, .content-text--image-splash .content-text__image {
    display: block;
    overflow: hidden;
    position: absolute;
    inset: 0;
    z-index: -1;
  }
  .content-text--image-splash .content-text__image {
    inset: 0 -200px;
  }
  .content-text--image-homepage .content-text__image {
    inset: 0 -30px;
  }
}
@media screen and (min-width: 1220px) {
  .content-text__content {
    padding-left: 10px;
    padding-right: 10px;
    width: 83.3333333333%;
  }
  .content-text--image-homepage .content-text__image {
    inset: 0 calc(-50vw + 590px);
  }
}
.content-text--secondary {
  position: relative;
}
.content-text--secondary:before {
  content: "";
  position: absolute;
  left: -20px;
  right: -20px;
  top: 0;
  bottom: 0;
  background: #DDEFFF;
  z-index: -1;
}
@media screen and (min-width: 768px) {
  .content-text--secondary:before {
    left: -30px;
    right: -30px;
  }
}
@media screen and (min-width: 1220px) {
  .content-text--secondary:before {
    left: calc(-50vw + 590px);
    right: calc(-50vw + 590px);
  }
}
.content-text--wave-start, .content-text--wave-stop, .content-text--wave-top {
  overflow-x: hidden;
  background: #DDEFFF;
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px;
  padding-right: 20px;
  mask-mode: alpha;
  mask-size: 100vw auto;
  mask-repeat: no-repeat;
}
@media screen and (min-width: 768px) {
  .content-text--wave-start, .content-text--wave-stop, .content-text--wave-top {
    margin-left: -30px;
    margin-right: -30px;
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media screen and (min-width: 1220px) {
  .content-text--wave-start, .content-text--wave-stop, .content-text--wave-top {
    margin-left: calc(-50vw + 590px);
    margin-right: calc(-50vw + 590px);
  }
  .content-text--wave-start .content-text__inside, .content-text--wave-stop .content-text__inside, .content-text--wave-top .content-text__inside {
    max-width: 1200px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
.content-text--wave-start {
  mask-image: url("../../images/wave-mobile--bottom.svg");
  mask-position: bottom;
  padding-bottom: 30vw;
}
@media screen and (min-width: 768px) {
  .content-text--wave-start {
    mask-image: url("../../images/wave-desktop--bottom.svg");
    mask-size: 3000px auto;
    padding-bottom: 330px;
    margin-bottom: -150px;
  }
}
.content-text--wave-stop {
  margin-bottom: 60px;
  mask-image: url("../../images/wave-mobile--top.svg");
  mask-position: top;
}
@media screen and (min-width: 768px) {
  .content-text--wave-stop {
    mask-image: url("../../images/wave-desktop--bottom.svg");
    mask-position: bottom;
    mask-size: 3000px auto;
    padding-bottom: 330px;
    margin-bottom: -150px;
  }
}
.content-text--wave-top {
  mask-image: url("../../images/wave-mobile--top.svg");
  mask-position: top;
}
@media screen and (min-width: 768px) {
  .content-text--wave-top {
    mask-image: url("../../images/wave-desktop--top.svg");
    mask-size: 3000px auto;
  }
}

@media screen and (min-width: 768px) {
  .content-text--wave-start .content-text__image--animation, .content-text--wave-stop .content-text__image--animation {
    margin-bottom: -150px;
  }
  .content-text--wave-start + .content-text--plain .content-text__image--animation {
    margin-top: -150px;
  }
}
.content-values {
  padding: 60px 0;
  position: relative;
}
.content-values:before {
  content: "";
  position: absolute;
  left: -20px;
  right: -20px;
  top: 0;
  bottom: 0;
  background: #DDEFFF;
  z-index: -1;
}
@media screen and (min-width: 768px) {
  .content-values:before {
    left: -30px;
    right: -30px;
  }
}
@media screen and (min-width: 1220px) {
  .content-values:before {
    left: calc(-50vw + 590px);
    right: calc(-50vw + 590px);
  }
}
.content-values__text {
  overflow: hidden;
}
.content-values__text-inside {
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 1em;
}
.content-values__text h2 {
  margin-top: 0;
}
.content-values__partner {
  float: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  margin-top: 10px;
  font-size: 9px;
  line-height: 19px;
  font-weight: 700;
  background: #fff;
  border-radius: 500px;
  box-shadow: 0 0 10px 3px rgba(208, 224, 240, 0.8);
}
.content-values__partner span {
  display: block;
  font-size: 19px;
}
.content-values__partner a {
  margin-top: 1em;
  text-decoration: underline;
}
.content-values__values {
  margin: 0 -20px;
}
.content-values__value {
  padding: 20px;
}
.content-values__value-inside {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px 3px rgba(221, 239, 255, 0.8);
}
.content-values .tns-nav {
  display: flex;
  justify-content: center;
}
.content-values .tns-nav button {
  margin: 0 3px;
  width: 9px;
  height: 9px;
  background: #F8A700;
  border: none;
  border-radius: 50%;
  opacity: 0.3;
}
.content-values .tns-nav button.tns-nav-active {
  opacity: 1;
}

@media screen and (min-width: 768px) {
  .content-values {
    padding: 100px 0;
  }
  .content-values__text {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    justify-content: space-between;
  }
  .content-values__text > * {
    flex-grow: 1;
    padding-left: 10px;
    padding-right: 10px;
  }
  .content-values__text-inside {
    padding-left: 10px;
    padding-right: 10px;
    width: 50%;
  }
  .content-values__partner {
    float: none;
    width: 300px;
    height: 300px;
    margin: 0 10px 0 0;
    font-size: 18px;
    line-height: 26px;
  }
  .content-values__partner span {
    font-size: 31px;
  }
  .content-values__values,
  .content-values #tns1 {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
  }
  .content-values__values > *,
  .content-values #tns1 > * {
    flex-grow: 1;
    padding-left: 10px;
    padding-right: 10px;
  }
  .content-values__values .tns-outer,
  .content-values #tns1 .tns-outer {
    padding: 0 10px !important;
  }
  .content-values__value {
    padding-left: 10px;
    padding-right: 10px;
    width: 33.3333333333%;
  }
  .content-values__value-inside {
    padding: 0;
    text-align: center;
    background: none;
    box-shadow: none;
  }
}
.tns-outer {
  padding: 0 !important;
}
.tns-outer [hidden] {
  display: none !important;
}
.tns-outer [aria-controls], .tns-outer [data-action] {
  cursor: pointer;
}

.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s;
}
.tns-slider > .tns-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}
.tns-horizontal.tns-subpixel > .tns-item {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}
.tns-horizontal.tns-no-subpixel:after {
  content: "";
  display: table;
  clear: both;
}
.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left;
}
.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%;
}

.tns-no-calc {
  position: relative;
  left: 0;
}

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px;
}
.tns-gallery > .tns-item {
  position: absolute;
  left: -100%;
  -webkit-transition: transform 0s, opacity 0s;
  -moz-transition: transform 0s, opacity 0s;
  transition: transform 0s, opacity 0s;
}
.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important;
}
.tns-gallery > .tns-moving {
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
}

.tns-autowidth {
  display: inline-block;
}

.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6;
}
.tns-lazy-img.tns-complete {
  opacity: 1;
}

.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s;
}

.tns-ovh {
  overflow: hidden;
}

.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0;
}

.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}

.tns-vpfix {
  white-space: nowrap;
}
.tns-vpfix > div, .tns-vpfix > li {
  display: inline-block;
}

.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden;
}
.tns-t-ct {
  width: calc(100% * 70 / 3);
  position: absolute;
  right: 0;
}
.tns-t-ct:after {
  content: "";
  display: table;
  clear: both;
}
.tns-t-ct > div {
  width: calc(100% / 70);
  height: 10px;
  float: left;
}